<template>
  <v-container>
    <v-row>
      <v-col
        v-for="form in filteredForms"
        :key="form.id"
        cols="12"
        md="6"
        lg="4"
        xl="3"
      >
        <v-card class="form-card" @click="selectForm(form)">
          <v-card-title class="form-title">{{ form.title }}</v-card-title>
          <v-card-subtitle class="form-description">{{
            form.description
          }}</v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ['forms', 'newStarter', 'sortMostFrequent', 'search', 'sortAsc'],
  computed: {
    filteredForms () {
      let filtered = this.forms.filter(
        (form) =>
          form.title.toLowerCase().includes(this.search.toLowerCase()) ||
          form.description.toLowerCase().includes(this.search.toLowerCase())
      );

      if (this.newStarter) {
        filtered = filtered.filter((form) => form.newStarter);
      }

      return filtered.sort((a, b) => {
        if (this.sortMostFrequent) {
          return a.id - b.id;
        } else if (this.sortAsc) {
          return a.title.localeCompare(b.title);
        } else {
          return b.title.localeCompare(a.title);
        }
      });
    }
  },
  methods: {
    selectForm (form) {
      this.$emit('form-selected', form);
    }
  }
};
</script>

<style scoped>
.form-card {
  height: 175px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 10px;
  transition:
    transform 0.2s,
    box-shadow 0.2s;
  background-color: #f7fcff;
}

.form-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.form-title {
  font-weight: bold;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
  white-space: pre-line; /* Handles the newlines */
}

.form-description {
  text-align: center;
  width: 100%;
  white-space: pre-wrap;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
