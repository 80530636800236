<template>
  <v-dialog v-model="dialog" max-width="800" persistent>
    <v-card class="dialog-card">
      <v-toolbar color="light-blue" dark class="dialog-toolbar">
        <v-toolbar-title>{{ selectedForm.title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="dialog-content">
        <div ref="formContainer">
          <iframe
            v-if="selectedForm"
            :src="`https://www.cognitoforms.com/f/${cognitoKey}/${selectedForm.formId}`"
            style="border: 0; width: 100%"
            :height="selectedForm.height"
            :title="selectedForm.title"
          ></iframe>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    selectedForm: {
      type: Object,
      default: () => null,
      required: false
    }
  },
  data () {
    return {
      dialog: this.isOpen,
      cognitoKey: process.env.VUE_APP_COGNITO_FORMS_KEY
    };
  },
  watch: {
    isOpen (newVal) {
      this.dialog = newVal;
    }
  },
  methods: {
    close () {
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.dialog-card {
  display: flex;
  flex-direction: column;
  max-height: 90vh;
}

.dialog-toolbar {
  position: sticky;
  top: 0;
  z-index: 1;
}

.dialog-content {
  flex: 1;
  overflow-y: auto;
}

.dialog-actions {
  flex-shrink: 0;
}

/* Custom scrollbar styles */
.dialog-content::-webkit-scrollbar {
  width: 12px;
}

.dialog-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.dialog-content::-webkit-scrollbar-thumb {
  background: #2196f3; /* Similar to light-blue color */
  border-radius: 10px;
}

.dialog-content::-webkit-scrollbar-thumb:hover {
  background: #1976d2; /* Darker shade on hover */
}
</style>
