import { createRouter, createWebHistory } from 'vue-router';
import MainPage from '@/views/MainPage.vue';

const routes = [
  {
    path: '/',
    name: 'MainPage',
    component: MainPage
  },
  {
    path: '/:formRoute',
    name: 'FormPage',
    component: MainPage,
    props: true
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
