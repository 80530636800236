<template>
  <v-container>
    <v-row class="align-center">
      <v-col cols="12" md="4" class="d-flex justify-start">
        <v-checkbox
          v-model="newStarter"
          label="New Starter"
          class="mr-4"
        ></v-checkbox>
        <v-checkbox
          v-model="sortMostFrequent"
          label="Most Frequent"
          class="mr-4"
        ></v-checkbox>
      </v-col>
      <v-col cols="12" md="4" class="text-center">
        <img src="/logo.jpg" alt="Logo" class="logo" />
      </v-col>
      <v-col cols="12" md="4" class="d-flex justify-end">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          class="search-bar mr-4"
          single-line
          hide-details
          variant="underlined"
        ></v-text-field>
        <v-btn text class="abc-btn" @click="toggleSort">
          ABC
          <v-icon>{{ sortAsc ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <form-list
      :forms="forms"
      @form-selected="openForm"
      :new-starter="newStarter"
      :sort-most-frequent="sortMostFrequent"
      :search="search"
      :sort-asc="sortAsc"
    ></form-list>
    <form-dialog
      :selectedForm="selectedForm"
      :isOpen="isDialogOpen"
      @close="closeDialog"
    ></form-dialog>
  </v-container>
</template>

<script>
import FormList from '@/components/FormList.vue';
import FormDialog from '@/components/FormDialog.vue';
import { ref, onMounted, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default {
  components: {
    FormList,
    FormDialog
  },
  setup () {
    const forms = ref([]);
    const selectedForm = ref(null);
    const isDialogOpen = ref(false);
    const route = useRoute();
    const router = useRouter();
    const newStarter = ref(false);
    const sortMostFrequent = ref(true);
    const search = ref('');
    const sortAsc = ref(true);

    const fetchForms = async () => {
      const response = await fetch('/forms.json');
      forms.value = await response.json();
    };

    const openForm = (form) => {
      selectedForm.value = form;
      isDialogOpen.value = true;
    };

    const closeDialog = () => {
      isDialogOpen.value = false;
      router.push({ name: 'MainPage' }); // Reset URL to main page
    };

    const toggleSort = () => {
      sortAsc.value = !sortAsc.value;
      sortMostFrequent.value = false;
    };

    onMounted(async () => {
      await fetchForms();
    });

    watchEffect(() => {
      if (route.params.formRoute) {
        const formRoute = route.params.formRoute;
        if (formRoute === 'newstarter') {
          newStarter.value = true;
        } else {
          const form = forms.value.find((f) => f.route === formRoute);
          if (form) {
            openForm(form);
          }
        }
      }
    });

    return {
      forms,
      selectedForm,
      isDialogOpen,
      openForm,
      closeDialog,
      newStarter,
      sortMostFrequent,
      search,
      sortAsc,
      toggleSort
    };
  }
};
</script>

<style scoped>
.logo-container {
  text-align: center;
  margin-bottom: 20px;
}

.logo {
  max-width: 100%;
  height: auto;
  width: 100px;
  /* Adjust size as needed */
}

.search-bar {
  border: none;
  box-shadow: none;
  background-color: transparent;
}

.abc-btn {
  background: none;
  border: none;
  box-shadow: none;
  color: inherit;
  cursor: pointer;
  margin-top: 10px;
}
</style>
