import { createApp } from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import router from './router';
import VueSafeHtml from 'vue-safe-html';

loadFonts();

createApp(App).use(router).use(vuetify).use(VueSafeHtml).mount('#app');
